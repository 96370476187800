import dotenv from "dotenv";
import { App } from "vue";
import * as Yup from "yup";
import { InputTypes } from "@/store/enums/StoreEnums";


/**
 * @description service to call HTTP request via Axios
 */
class SurveyService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  public surveyInputList: any = [];

  constructor(surveyInputList: Array<any>) {
    this.surveyInputList = surveyInputList;
  }

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    SurveyService.vueInstance = app;
  }

  /**
   * @description set the default HTTP request headers
   */
  public getInputByStep(step: number): any {
    const current_step = this.surveyInputList.filter(
      (item) => item.input.set_id == step
    );
    return current_step;
  }

  /**
   * @description reduce the input array by single step id
   */
  public getInputReducedByStep(step: number): any {
    const current_step = this.surveyInputList.filter(
      (item) => item.input.set_id == step
    );
    const reduced_current_step = current_step.reduce(function (obj, step) {
      if (!SurveyService.isEmptyObj(obj)) {
        let found = false;
        if (obj.filter((e) => e.input.id === step.input.id).length > 0) {
          found = true;
        }
        if (!found) {
          obj.push(step);
        }
      } else {
        obj.push(step);
      }
      return obj;
    }, []);

    return reduced_current_step;
  }

  /**
   * @description get input by same id
   */
  public getInputByIdGroup(step: number, id_input: number): any {
    const input_by_group_id = this.surveyInputList.filter(
      (item) => item.input.set_id === step && item.input.id == id_input
    );
    return input_by_group_id;
  }

  /**
  * @description get input by same id
  */
  public getValue(item: any, sub_id: number): any {
    const result = this.surveyInputList.filter(
      (survey) => survey.input.set_id === item.set_id && survey.input.id == item.id && survey.input.sub_id == sub_id
    );
    if (result.length > 0) return result[0].input.value;
    return "#";
  }

  /**
   * @description get input by same id
   */
  public static getInputByIdGroup(
    step: number,
    id_input: number,
    array_input: Array<any>
  ): any {
    const input_by_group_id = array_input.filter(
      (item) => item.input.set_id === step && item.input.id == id_input
    );
    return input_by_group_id;
  }

  /**
   * @description get single input object from gender field type
   */
  public static getSingleInputIntegerGenderField(
    step: number,
    id_input: number,
    sub_id: number,
    array_input_list: Array<any>
  ): any {
    const input_by_group_id = array_input_list.filter(
      (item) =>
        item.input.set_id === step &&
        item.input.id == id_input &&
        item.input.sub_id == sub_id
    );
    return input_by_group_id[0];
  }

  /**
   * @description get single input object from float field type
   */
  public static getSingleFloatIntegerGenderField(
    step: number,
    id_input: number,
    sub_id: number,
    array_input_list: Array<any>
  ): any {
    const input_by_group_id = SurveyService.getSingleInputIntegerGenderField(
      step,
      id_input,
      sub_id,
      array_input_list
    );
    return input_by_group_id;
  }

  public static isEmptyObj(obj): boolean {
    if (obj !== undefined) {
      return Object.keys(obj).length === 0;
    }
    return true;
  }

  public static getSchemaByInput(
    step: number,
    surveyInputList: Array<any>,
    t: any
  ): any {
    const current_step = surveyInputList.filter(
      (item) => item.input.set_id == step
    );
    const createAccountSchema: any = {};
    if (current_step.length > 0) {
      current_step.forEach((element) => {
        const key = "input" + element.kpi.auto_number;
        let element_schema: any = null;
        if (
          element.kpi.type == InputTypes.INTEGER_GENDER_FIELD ||
          element.kpi.type == InputTypes.FLOAT_GENDER_FIELD
        ) {
          if (element.kpi.optional)
            element_schema = Yup.number()
              .transform((_, val) => {
                return Number(val);
              })
              .min(0, t("FIELD_SHOULD_BE_INTEGER"))
              .nullable(true)
              .typeError(t("FIELD_SHOULD_BE_NUMBER"));
          else
            element_schema = Yup.number()
              .required(t('FIELD_REQUIRED'))
              .typeError(t('FIELD_REQUIRED'));
        } else if (element.kpi.type == InputTypes.TEXT_AREA_FIELD) {
          if (!element.kpi.optional)
            element_schema = Yup.string().required(t('FIELD_REQUIRED'));
        } else if (
          element.kpi.type == InputTypes.SELECT_YES_NO_FIELD ||
          element.kpi.type == InputTypes.ROLE_GENDER_FIELD
        ) {
          if (!element.kpi.optional)
            element_schema = Yup.string().required(t('FIELD_REQUIRED')).typeError(t('FIELD_REQUIRED'));
        } else {
          console.log(element.kpi.type);
        }
        createAccountSchema[key] = element_schema;
      });
    }

    return Yup.object().shape(createAccountSchema);
  }

  public static getInputName(): string {
    return "nice";
  }

  public static isInArray(array: Array<any>, search_key, find_value) {
    return array.find((item) => item[search_key] === find_value);
  }
}

export default SurveyService;
